<template>
    <div class="wishList">
        <div class="text-center px-3 deal-list">
            <div v-if="list&&list.length>0">
                <ul class="last-border-0">
                    <li v-for="(item,index) in list" :key="index" class="border-bottom border-yellow-1 py-3">
                        <div class="d-flex justify-content-between align-items-end">
                            <Item :item="item"  :own_deal="true" class="flex-shrink-0 item" />
                            <ul class="ms-3 fs-px-12">
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Product Amount</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.price}} {{item.coin}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Ticket Quantity</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.total}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Ticket Price</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.d_price}} {{item.deal_coin}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Estimated Profit</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.profit}}%</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Expiry Time</p>
                                    <p class="text-white fw-bold w-100 text-end">{{$formatDate_yymmdd_hhmm(item.close_time)}}</p>
                                </li>
                            </ul>
                        </div>
                        <!-- <button class="btn btn-main fw-bold h-px-50 w-100" @click="CancelDealConfirm(item)">Cancel Deal</button> -->
                    </li>
                </ul>
            </div>
            <div v-else class="px-3 py-7">
                <p class="text-white mb-4">
                    You are not selling yet,<br>
                    go upload a deal an be a seller!
                </p>
                <button class="btn btn-main fw-bold py-2" @click="$router.push('/upload-ticket')">Go Upload a Deal</button>
            </div>
        </div>

        
        <b-modal v-model="cancelModal" centered hideHeader content-class="rounded-2" >
            <p class="text-center mb-4 fs-px-14 fw-bold">If you cancel a transaction early<br>
            A fee will apply.</p>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Cancellation fee rate</span>
                    <span class="fw-bold">{{dealer_cancel_fee}} %</span>
                </div>
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Cancellation fee amount</span>
                    <span class="fw-bold">{{dealer_cancel_fee_price}} {{info.coin}}</span>
                </div>
                <div class="flex-between-center">
                    <span>balance in my wallet</span>
                    <span class="fw-bold">{{user_balance}} {{info.coin}}</span>
                </div>
            </div>
            <p class="text-center text-danger fs-px-12 pt-3">Are you sure you want to cancel?</p>
            <template #modal-footer>
                <div class="d-flex w-100 px-3">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="cancelModal=!cancelModal">Back</button>
                    <button class="py-2 fs-px-12 fw-bold btn bg-yellow-1 w-50" @click="CancelDeal()">Confirm</button>
                </div>
            </template>
        </b-modal>

        <Footer></Footer>
    </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import List from '@/components/mydeal/List.vue'
import Footer from '@/components/common/Footer.vue'
import Item from '@/components/mydeal/ListItem.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');

export default {
    name:'WishList',
    components:{
        List, Footer, Item, BModal
    },
    data(){
        return{
            cancelModal: false,
            cancel_fee_price: '',
            cancel_fee: '',
            t_coin :'',
            t_code :'',
            user_balance: '',
            code : this.$route.params.code,
            dealer_cancel_fee :'',
            dealer_cancel_fee_price : '',
            info:{},
            list:[]
        }
    },
    mounted(){
        this.GetSellParticipate();
        // this.GetDealerCancelFee();
    },
    methods : {
        GetDealerCancelFee(){
            const body = {};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetDealerCancelFee',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.cancel_fee = body.cancel_fee;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        CancelDeal(){
            const code = this.t_code;
            const body = {code};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/CancelDeal',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                        this.$alert('Cancellation Complete.').then(()=>{
                            this.$router.push({path:'/transaction/history'})
                        })
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            
        },
        GetSellParticipate(){
            const code = this.code
            const body = {code};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetSellParticipate',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.list = body.list;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetWalletBalance(){
            const symbol = this.t_coin;
            const body = {symbol};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mydeal/GetWalletBalance',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.user_balance = body.balance;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },

        CancelDealConfirm(item){
            this.t_coin = item.coin;
            this.t_code = item.code;
            const cancel_fee = this.cancel_fee;

            const price = item.price;

            const cancel_fee_price = exactMath.mul(price,cancel_fee,0.01)
            this.cancel_fee_price = cancel_fee_price;

            this.GetWalletBalance();
            this.cancelModal = true;
        },
        
        // CancelDeal(){
        //     if( true ){ //잔액 있을 시
        //         this.$alert('거래가 취소되었습니다.').then(()=>{
        //             this.$router.push('/transaction/history')
        //         })
        //     }else{
        //         this.$alert('잔액이 부족합니다.').then(()=>{
        //             this.cancelModal = false;
        //         })
        //     }
        // },
    }
}
</script>
<style lang="scss" scoped>
.item{
    min-width: 150px;
}
</style>
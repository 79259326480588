<template>
    <div class="wishList">
        <div class="text-center px-3 deal-list">
            <div v-if="list&&list.length>0">
                <ul class="last-border-0">
                    <li v-for="(item,index) in list" :key="index" class="border-bottom border-yellow-1 py-3">
                        <p class="text-white text-start mb-2">{{$formatDate_yymmdd(item.cancel_time)}}</p>
                        <div class="d-flex justify-content-between align-items-end">
                            <Item :item="item"  :own_deal="true" class="flex-shrink-0 item" />
                            <ul class="ms-3 fs-px-12">
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Product Amount</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.price}} {{item.coin}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Ticket Quantity</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.total}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Ticket Price</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.d_price}} {{item.deal_coin}}</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Estimated Profit</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.profit}}%</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Expiry Time</p>
                                    <p class="text-white fw-bold w-100 text-end">{{$formatDate_yymmdd_hhmm(item.close_time)}}</p>
                                </li>
                            </ul>
                        </div>
                        <button class="btn bg-gray-300 text-white h-px-50 w-100 fs-px-15 fw-300">Cancelled : {{item.close_message}}</button>
                    </li>
                </ul>
            </div>
            <div v-else class="px-3 py-7">
                <p class="text-white mb-4">
                    No cancelled Deal
                </p>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>
<script>
import List from '@/components/mydeal/List.vue'
import Footer from '@/components/common/Footer.vue'
import Item from '@/components/mydeal/ListItem.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');

export default {
    name:'WishList',
    components:{
        List, Footer, Item
    },
    data(){
        return{
            cancelModal: false,
            cancel_fee_price: '',
            cancel_fee: '',
            t_coin :'',
            t_code :'',
            user_balance: '',
            code : this.$route.params.code,
            list:[]
        }
    },
    mounted(){
        this.GetSellCancel();
    },
    methods : {
        GetSellCancel(){
            const code = this.code;
            const body = {code};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetSellCancel',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.list = body.list;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
    }
}
</script>
<style lang="scss" scoped>
.item{
    min-width: 150px;
}
</style>

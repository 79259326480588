<template>
    <div class="wishList">
        
        <SubHeader :title="`${ nickname } Deals`" :link="''" right_home_btn/>
        <div class="p-3">
            <div class="pt-0 d-flex text-center fw-400">
                <div class="rounded-start py-3 w-50"
                    :class="{'bg-yellow-1 fw-bold': head_path==0 , 'bg-yellow-4 text-gray-500' : head_path !=0}"
                    @click="head_path = 0; $router.push({path:`/user/buy/wish-list/${user_code}`})"
                >BUY</div>
                <li class="rounded-end py-3 w-50"
                    :class="{'bg-yellow-1 fw-bold': head_path==1 , 'bg-yellow-4 text-gray-500' : head_path !=1}
                    "
                    @click="head_path = 1; $router.push({path:`/user/sell/sale/${user_code}`})"
                >SELL</li>
            </div>
        </div>

        <ul class="flex-around-center px-3 pb-3 gap-3 text-center fs-px-12 sub_tab_list" v-show="head_path==0">
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/buy/wish-list/${user_code}`">WishList</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/buy/participate/${user_code}`">Participating Deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/buy/won/${user_code}`">Won Deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/buy/closed/${user_code}`">Closed Deals</router-link>
            </li>
        </ul>

        <ul class="flex-center-center px-3 pb-3 gap-5 text-center fs-px-12 sub_tab_list" v-show="head_path==1">
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/sell/sale/${user_code}`">Deal In progress</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/sell/soldout/${user_code}`">Closed deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" :to="`/user/sell/cancel/${user_code}`">Seller Cancelled</router-link>
            </li>
        </ul>
        <router-view />
        <Footer></Footer>
    </div>
</template>
<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    name:'WishList',
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            user_code : this.$route.params.code,
            nickname :"",
            // front ui
            head_path: 'buy',
        }
    },
    mounted(){
        this.GetHeadPath();
        this.GetUserInfo()
    },
    watch:{
      $route(to, from) {
        if (to.path != from.path) {
            this.GetHeadPath()
        }
      }
    },
    methods:{
        GetUserInfo(){
            const code = this.user_code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetUserInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.nickname = body.info.nick_name;
                            
                        }
                    }
                }   
            )
        },
        GetHeadPath(){
            const firstTwoPaths = this.$route.path.substring(1).split('/').slice(0, 2);
            if (firstTwoPaths[1] === 'buy') {
                this.head_path = 0;
            }else if (firstTwoPaths[1] === 'sell'){
                this.head_path = 1;
            }
        }
    }

}
</script>
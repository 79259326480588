<template>
    <div class="wishList">
        <div class="text-center">
            <p v-if="list&&list.length>0" class="text-start small text-burgundy-4 px-3 mb-2 lh-sm">Announced deals will be automatically deleted after three months time.</p>
            <div v-if="list&&list.length>0" class="p-3">
                <!-- 날짜 그룹인 경우 -->
                <!-- <div v-for="(sorted_list, date) in sorted_by_date_list" :key="date">
                    <p class="text-white text-start mb-2">{{date}}</p>
                    <CloseListItem v-for="(item, index) in sorted_list" :key="index" :item="item" :index="index" class="mb-3" />
                </div> --> 

                <!-- 날짜 각각 표시인 경우 -->
                <div v-for="(item, index) in list" :key="index">
                    <p class="text-white text-start mb-2">{{item.close_date}}</p>
                    <CloseListItem :item="item" :index="index" class="mb-3" />
                </div> 

            </div>
            <div v-else class="px-3 py-7">
                <p class="text-white mb-4">
                    Oops! You have not won a single deal yet.<br>
Go check out ‘closing soon deals’ to win one!
                </p>
                <button class="btn btn-main fw-bold py-2" @click="$router.push('/')">Go to Closing Soon Deals</button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import CloseListItem from '@/components/deal/CloseListItem.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        CloseListItem, Footer
    },
    data(){
        return{
            // list:[],
            code : this.$route.params.code,
            list:[ ],
        }
    },
    mounted(){
        this.GetWin();
    },
    methods : {
        GetWin(){
            const code = this.code;            
            const body = {code};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetBuyerWin',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.list = body.list;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
    },
    computed:{
        // sorted_by_date_list() {
        //     const grouped = {};
        //     for (const item of this.list) {
        //         let _item_close_date = this.$formatDate_yymmdd(item.close_date);

        //         if (!grouped[_item_close_date]) {
        //         grouped[_item_close_date] = [];
        //         }
        //         grouped[_item_close_date].push(item);
        //     }

        //     const sorted_group = Object.entries(grouped).sort((a, b) => {
        //         return new Date(b[0]) - new Date(a[0]);
        //     });
        //     return Object.fromEntries(sorted_group);
        // },
    }


}
</script>
<template>
    <div class="wishList">
        <div class="text-center px-3 deal-list">
            <div v-if="list&&list.length>0">
                <ul>
                    <li v-for="(item,index) in list" :key="index">
                        <div class="d-flex justify-content-between align-items-end">
                            <Item :item="item" class="flex-shrink-0 item" />
                            
                            <ul class="mb-3 ms-3 fs-px-12">
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1 flex-between-center w-100"  @click="CheckDetail(item)">
                                        <span>My Purchase</span>
                                        <span class="badge bg-burgundy-4 text-burgundy-0 py-0"><i class="fas fa-ellipsis-h"></i></span>
                                    </p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.history.total}} Tickets</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Total Purchase Amount</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.history.total_count}} {{item.history.deal_coin}} (= $ {{item.history.total_price}})</p>
                                </li>
                                <li class="flex-between-center flex-wrap mb-3">
                                    <p class="text-burgundy-4 mb-1">Chance of Winning</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.history.profit}} %</p>
                                </li>
                                <li class="flex-between-center flex-wrap">
                                    <p class="text-burgundy-4 mb-1">Estimated Profit</p>
                                    <p class="text-white fw-bold w-100 text-end">{{item.history.benifit}} %</p>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else class="px-3 py-7">
                <p class="text-white mb-4">
                    You are not part of one signle deal..<br>
                    Go check out the hottest deals right now!
                </p>
                <button class="btn btn-main fw-bold py-2" @click="$router.push('/deal/list/hot')">Go to Hot Deal</button>
            </div>
        </div>
        
        <b-modal v-model="detailModal" centered hideHeader hideFooter >
            <div>
                <h6 class="text-center mb-2 py-2"><i class="fal fa-times fs-5 text-yellow-1 position-absolute end-0 me-3" @click="detailModal= !detailModal"></i>View Details</h6>
                    <div class="fs-px-12">
                        <div class="flex-between-center mb-3">
                        <span>Deal Number</span>
                        <span class="fw-bold">{{d_code}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>Nickname</span>
                        <span class="fw-bold">{{d_nickname}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>UID</span>
                        <span class="fw-bold">{{d_uid}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>Purchased ticket quantity</span>
                        <span class="fw-bold">{{d_total}}</span>
                    </div>
                    <div class="flex-column mb-1">
                        <span>Purchased ticket numbers</span>
                        <ul class="ticket_num_box">
                            <li class="badge bg-yellow-4 text-black" v-for="(item, index) in d_tickets" :key="index">{{item}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-modal>

        <Footer></Footer>
    </div>
</template>
<script>
import { BAvatar, BModal } from 'bootstrap-vue'
import List from '@/components/mydeal/List.vue'
import Footer from '@/components/common/Footer.vue'
import Item from '@/components/mydeal/ListItem.vue'
const CryptoJS = require("crypto-js");


export default {
    components:{
        List, Footer, Item, BModal
    },
    data(){
        return{
            detailModal: false,
            d_nickname : '',
            d_tickets : '',
            d_total :'',
            d_uid : '',
            d_code : '',
            list:[]
        }
    },
    mounted(){
        this.GetParticipate();
    },
    methods : {
        CheckDetail(item){
            if(item.my_deal =='Y'){
                this.$router.push({path:`/deal/detail/${item.code}`})
            }else{
                this.d_code = item.code;
                this.d_nickname = item.history.nick_name;
                this.d_tickets = item.history.tickets;
                this.d_uid = item.history.uid;
                this.d_total = item.history.total;
                this.detailModal = true;
            }
            
        },
        GetParticipate(){
            
            const body = {};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mydeal/GetParticipate',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.list = body.list;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
    }
}
</script>
<style lang="scss" scoped>
.item{
    min-width: 150px;
}
</style>